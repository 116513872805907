@import url("https://cdn.jsdelivr.net/gh/mailtoharshit/San-Francisco-Font-/sanfrancisco.css");
@import "./variables";
@import "~bulma/sass/utilities/functions";
// Import Bulma styles
@import "~bulma/bulma";

// Import rbx style fixes
@import "~rbx/rbx";

// Custom styles
@import "./utils/spacing-helpers";

html,
body,
#root {
  font-size: 12px;
  background: #f6f8f9;
  overflow: auto;
}

body {
  background: #f6f8f9;
}

* {
  font-family: "Montserrat", sans-serif;
}

a {
  text-decoration: none;
}

table,
thead,
tbody,
th,
td,
tr {
  border: none;
}

.content ol,
.content ul {
  margin: 0;
  padding: 0;
}
